import React, { useState, useEffect, useMemo } from 'react'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

import _ from 'lodash'
import useLocalStorage from '@rehooks/local-storage'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider, useDispatch } from 'react-redux'
import axios from 'axios'
import curlirize from 'axios-curlirize'
const qs = require('qs')

import { theme } from './utils/theme'
import RoutePage from './Route'
import PageLoading from './components/PageLoading'
import { setupStore } from './redux/config/configStore'
import { learnerCheckIn, profile } from './utils/apiPath'
import {
  openErrorWithCurlDialog,
  openDialog,
  openErrorDialog,
} from './redux/slices/alertDialog'
import AlertDialog from './components/AlertDialog'
import Error401 from './components/AlertDialog/Error401'
import { AuthProvider, useAuth } from 'react-oidc-context'
import keycloakConfig from './keycloak'
import ErrorExpire from './components/AlertDialog/ErrorExpire'
import { parseJwt } from './utils/lib'
import { jwtIgnore } from './constants/path'
import { handleExceptPath } from './events'
import OneAccount from './OneAccount'

export const store = setupStore()

export const instanceKeycloak = axios.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  timeout: 60000,
})

export const instanceOneAccount = axios.create({
  baseURL: `${OneAccount.authority}`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  timeout: 10000,
})

const Root = () => {
  const [type] = useLocalStorage('type')

  const oidcConfig = useMemo(() => {
    if (type === 'Staff' && window.__env__.ENV !== 'DEV') {
      return OneAccount
    }
    return keycloakConfig(type)
  }, [type])

  return (
    <AuthProvider {...oidcConfig}>
      <Provider store={store}>
        <App />
      </Provider>
    </AuthProvider>
  )
}

export default Root

export const App = () => {
  const auth = useAuth()
  const dispatch = useDispatch()

  const user = JSON.parse(localStorage.getItem('user'))
  const [, setType] = useLocalStorage('type')
  const [isLoading, setLoading] = useState(false)
  const [IsStatus, setStatus] = useState(0)

  const refreshToken = async () => {
    try {
      const userType = localStorage.getItem('type')
      const keycloak = keycloakConfig(userType)
      const refreshToken = localStorage.getItem('refresh_token')
      const body = {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        scope: 'openid offline_access',
        client_id: keycloak.client_id,
      }
      if (keycloak.client_secret) {
        body['client_secret'] = keycloak.client_secret
      }
      if (userType === 'Staff' && window.__env__.ENV !== 'DEV') {
        const oneAcc = OneAccount
        body['client_id'] = oneAcc.client_id
        body['scope'] = oneAcc.scope
        body['client_secret'] = oneAcc.client_secret
        const getToken = await instanceOneAccount({
          method: 'post',
          url: `/as/token.oauth2`,
          data: qs.stringify(body),
        })
        const { refresh_token, access_token, token_type } = getToken.data
        localStorage.setItem('refresh_token', refresh_token)
        localStorage.setItem('token', `${token_type} ${access_token}`)
        return `${token_type} ${access_token}`
      } else {
        instanceKeycloak.defaults.baseURL = `${keycloak.authority}`
        const getToken = await instanceKeycloak({
          method: 'post',
          url: `/protocol/openid-connect/token`,
          data: qs.stringify(body),
        })

        const { refresh_token, access_token, token_type } = getToken.data

        localStorage.setItem('refresh_token', refresh_token)
        localStorage.setItem('token', `${token_type} ${access_token}`)
      }
    } catch (error) {
      console.log('error ->', error)
      return 'expire'
    }
  }

  axios.interceptors.request.use(
    async (config) => {
      config.baseURL = window.__env__.REACT_APP_API_URL
      const ignore = !_.isEmpty(jwtIgnore.find((el) => el === config.url))
      const accessToken = auth.user?.access_token
      const token = localStorage.getItem('token')
      if (!_.isEmpty(token) && !ignore) {
        config.headers.Authorization = `${token}`
      } else if (accessToken && !ignore) {
        config.headers.Authorization = `${auth.user.token_type} ${accessToken}`
      } else if (_.isEmpty(token)) {
        config.headers.Authorization = 'NoToken'
      }
      config.timeout = 60000
      return config
    },
    function (err) {
      return Promise.reject(err)
    }
  )

  axios.interceptors.response.use(
    async (response) => {
      const getToken = localStorage.getItem('token')

      const token = getToken?.replace('Bearer ', '')
      const decodedJwt = parseJwt(token)

      if (decodedJwt && token) {
        const expire = 60000 * 10
        const expirationTime = decodedJwt?.exp * 1000 - expire
        const dateNow = new Date()
        if (dateNow >= expirationTime) {
          await refreshToken()
        }
      }
      return response
    },
    async (error) => {
      const status = _.get(error, 'response.status', 500)
      const url = _.get(error, 'config.url', '')
      console.log('status ->', status)
      const config = error?.config
      if (status === 401 && !config?.sent) {
        config.sent = true
        config.expire = await refreshToken()
        config.headers = { ...config.headers }
        return axios(config)
      }

      if (status === 401 && config?.expire === 'expire') {
        dispatch(
          openDialog({
            type: 'custom',
            content: <ErrorExpire />,
            isCloseDialog: false,
            zIndex: 5000,
          })
        )
        return Promise.reject(error)
      }

      if (status === 401) {
        dispatch(
          openDialog({
            type: 'custom',
            content: <Error401 />,
            isCloseDialog: false,
          })
        )
        return Promise.reject(error)
      }
      console.log('learnerCheckIn ->', learnerCheckIn)
      console.log('url ->', url)
      if (url === learnerCheckIn) return Promise.reject(error)
      if (status >= 500 && status < 600) {
        dispatch(openErrorWithCurlDialog(error))
        return Promise.reject(error)
      }
      const body = _.get(error, 'response.data.constraints', null)
      console.log('body ->', body)
      if (!_.isEmpty(body)) {
        dispatch(openErrorDialog({ ...body, message: body?.value }))
        return Promise.reject(error)
      }

      return Promise.reject(error)
    }
  )

  curlirize(axios, (result, err) => {
    if (err) {
      console.error(err)
    }
  })

  const handleGetProfileSuccess = (res) => {
    const userData = _.get(res, 'data', null)
    const isProspect = _.get(userData, 'isProspect', false)
    localStorage.setItem('user', JSON.stringify(userData))
    setLoading(false)
    if (isProspect) {
      dispatch(
        openDialog({
          isCloseDialog: false,
          iconType: 'warning',
          title: 'รวมข้อมูลประวัติผู้ใช้งาน',
          message: [
            'ระบบจะเปลี่ยนบัญชีผู้ใช้งานให้กับคุณ',
            'และรวมข้อมูลส่วนตัวพร้อมประวัติการเรียนให้คุณอัตโนมัติ',
          ],
          buttonRight: { label: 'รับทราบ' },
        })
      )
    }
  }

  const handleGetProfileError = (err) => {
    setLoading(false)
    const errStatus = _.get(err, 'response.status', 500)
    window.localStorage.setItem('log_error', errStatus)
    setStatus(errStatus)
    if (errStatus !== 0) {
      window.location.href = '/login'
    }
  }

  useEffect(() => {
    return auth.events.addUserLoaded((authorization) => {
      if (!_.isEmpty(authorization?.access_token) && _.isEmpty(user)) {
        axios.defaults.baseURL = window.__env__.REACT_APP_API_URL
        axios.defaults.headers = {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-store',
          Pragma: 'no-cache',
          Expires: '0',
          Authorization: `${authorization.token_type} ${authorization?.access_token}`,
        }
        setLoading(true)
        localStorage.setItem('refresh_token', authorization.refresh_token)
        localStorage.setItem(
          'token',
          `${authorization.token_type} ${authorization?.access_token}`
        )
        const type = localStorage.getItem('type')

        if (type === 'Agent') {
          window.location = '/waiting-for-login'
        } else {
          axios
            .get(profile)
            .then((res) => handleGetProfileSuccess(res))
            .catch((err) => handleGetProfileError(err))
        }
      }
    })
  }, [auth.events, user])

  useEffect(() => {
    let logerror = window.localStorage.getItem('log_error')
    if (IsStatus === 401 || logerror === '401') {
      dispatch(
        openDialog({
          type: 'custom',
          content: <Error401 />,
          isCloseDialog: false,
        })
      )
    }
  }, [IsStatus])

  const onFocus = () => {
    if (
      _.isEmpty(window.localStorage.getItem('user')) &&
      !handleExceptPath(window.location)
    )
      window.location.href = '/login'
  }

  useEffect(() => {
    window.addEventListener('focus', onFocus)
    return () => {
      window.removeEventListener('focus', onFocus)
    }
  }, [user, window?.location?.pathname])

  const handleSwitchFromBackend = () => {
    if (_.isNull(user)) {
      setType('Staff')
      window.localStorage.setItem('previousPath', '/my-profile')
      window.location = '/logging-in'
    } else {
      window.location = '/my-profile'
    }
  }

  useEffect(() => {
    if (window?.location?.pathname !== '/switch-from-backend') return
    handleSwitchFromBackend()
  }, [user, window?.location?.pathname])

  return (
    <ThemeProvider theme={theme}>
      <PageLoading />
      <AlertDialog />
      <CssBaseline />
      {!isLoading ? (
        <AppRoute user={user} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: '#FFFFFF',
            zIndex: '99999',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={30} />
          <Typography sx={{ mt: 2 }}>กรุณารอสักครู่</Typography>
        </Box>
      )}
    </ThemeProvider>
  )
}

export const AppRoute = ({ user }) => {
  const auth = useAuth()

  if (auth.isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          backgroundColor: '#FFFFFF',
          zIndex: '99999',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={30} />
        <Typography sx={{ mt: 2 }}>กรุณารอสักครู่</Typography>
      </Box>
    )
  }

  return (
    <Router>
      <RoutePage user={user} />
    </Router>
  )
}
