export const profile = '/user/profile'
// e-evaluation
export const eEvaluation = '/e-evaluation'
// e-testing
export const eTesting = '/e-testing'
export const eTestingAnswerNow = '/e-testing/ga'
export const eTestingScore = '/e-testing/get-learner-testing-score'
export const eTestingAnswer = '/e-testing/get-correct-answers'
// document
export const learnerDocument = '/manage-class/learner-document'
export const registerDocument = '/manage-class/upload-file/register'
export const uploadLearnerDocument = '/file/learner-document'
export const additionalConsent = '/prospect/consent'
export const additionalConsentCreate = '/prospect/create'
export const additionalConsentSteal = 'prospect/steal'
export const additionalCancelSteal = 'prospect/cancel-steal'
export const prospectExamination = '/prospect/examination'
export const prospectExaminationHistory = '/prospect/examination/history'
export const prospectDeleteManagerExamination = '/examination/manager/student'

// checkIn
export const learnerCheckIn = '/manage-Class/check-in'
// content
export const manageContentHome = '/manage-content'
export const manageContentLogin = '/manage-content/login'
// course
export const courseFilter = '/manage-class/all-course/get-filter'
export const courseList = '/manage-class/all-course/get-course'
export const courseDetail = '/manage-class/get-course-detail'
export const courseClassList =
  '/manage-class/get-training-plan-by-course-version'
export const courseClassDetail = '/manage-class/get-training-plan-class-detail'
export const courseClassRegister = '/manage-class/register'
export const checkPrerequisite = '/manage-class/check-prerequisite'
// prospect
export const prospect = '/prospect'
export const prospectSubmit = '/prospect/submit'
export const prospectUploadImage = '/file/prospect'
export const prospectDownloadTemplate = '/prospect/draft/downloadTemplate'
export const prospectUploadTemplate = '/prospect/draft/upload'
export const prospectSubmitTemps = '/prospect/submit-temps'
export const prospectFilter = '/prospect/filter'
export const prospectDownload = '/prospect/download'
export const prospectCourseList = '/prospect/course/list'
export const prospectCheckProspect = '/prospect/check-prospect'
export const prospectCheckAddList = 'prospect/add-list'
export const prospectResend = '/prospect/resend'
export const prospectRegisterAadTest = '/prospect/register-and-test'
export const prospectCancelRegistration = 'prospect/cancel-registration'
export const prospectHistory = '/prospect/registration/history'
export const prospectSentMail = '/prospect/send-email-temp'
export const prospectDeleteMail = '/prospect/send-email-delete-prospect'

export const prospectAddToClass = 'prospect/add-to-class'

// location
export const location = '/location'

// class
export const classLearner = '/manage-class/get-class-learner'
export const myClassList = '/manage-class/get-my-class-v2'
export const myClassHistory = '/manage-class/get-my-class/history'
export const cancelClass = '/manage-class/cancel-class'
export const checkExpiryQRCode = '/manage-class/check-expiry-qr-code'

// user
export const mayProfile = '/user'
export const userCalendar = '/user/calendar'
export const eCertificationDownload = '/e-certification/certificate'
export const eLearnintCertificateDownload =
  '/e-certification/e-learning-certification'
export const learnerTodoList = '/manage-class/get-learner-todo-list'
export const learnerTodoListStandalone =
  '/manage-class/get-learner-todo-list-standalone'
export const userLogoutEvent = '/user/logout'
export const updateUserProfile = '/user/update-profile'
export const updateUserProfileIdCardNo = '/user/update-profile/id-card-no'

// queue
export const enrollQueue = '/queue/enroll'

// e-learning
export const eLearningConnection = '/e-learning/connection'
export const eLearning = '/e-learning'
export const eLearningFilter = '/e-learning/all-course/get-filter'
export const eLearningList = '/e-learning/all-course/get-course'
export const eLearningOngoingCourse =
  '/e-learning/all-course/get-ongoing-course'
export const eLearningGetLikeCourse = '/e-learning/all-course/get-like-course'
export const eLearningDetail = '/e-learning/get-course-detail'
export const eLearningLikeCourse = '/e-learning/like'
export const eLearningBatch = '/e-learning/course/batch'
export const eLearningCourseBatchDetail = '/e-learning/course/batch/detail'
export const eLearningEnrollIdCard = '/e-learning/course/enroll/id-card'
export const eLearningEnrollRekognition =
  '/e-learning/course/enroll/rekognition'
export const eLearningEnroll = '/e-learning/course/enroll'
export const eLearningBatchLearnerStatus =
  '/e-learning/course/batch/learner/status'
export const eLearningVerifyIdCard =
  '/face-rekognition/e-learning/verify/id-card'
export const eLearningVerifyFace = '/face-rekognition/e-learning/verify/face'
export const eLearningQueueSession = '/queue/session'
export const eLearningFailScanCount = '/e-learning/course/register/count'
export const eLearningBatchDetail = '/e-learning/learners/course/batch'
export const eLearningModule = '/e-learning/module'
export const eLearningModuleDownloadFile = '/e-learning/moduleFile'
export const eLearningCourseBatchScanCount = '/e-learning/course/scanFace/count'
export const eLearningCourseLessonLearning =
  '/e-learning/course/lesson/learning'
export const eLearningCourseLogLearner = '/e-learning/course/elog/learner'
export const eLearningCourseLogLearnerPerLesson =
  '/e-learning/course/elog/learner-per-lesson'
export const eLearningCourseLogLearnerPerLessonReset =
  '/e-learning/course/elog/learner-per-lesson/reset'
export const eLearningETesting = '/e-learning/e-testing'
export const eLearningETestingAnswer = '/e-learning/e-testing/answer'
export const eLearningETestingCorrectAnswer =
  '/e-learning/e-testing/get-correct-answers'
export const eLearningETestingLearnerScore =
  '/e-learning/e-testing/get-learner-testing-score'
export const eLearningEEvaluation = '/e-learning/e-evaluation'
export const eLearningBatchQuestion = '/e-learning/course/batch/question'
export const eLearningLogClick = '/e-learning/course/elog/click'

export const eLearningLearnerList = '/e-learning/course/batch/learner/filter'
export const eLearningCourseList = '/e-learning/learners/all-course/get-course'
export const eLearningBanner = '/e-learning/learners/main/get-banner'
export const eLearningCourseRecommend =
  '/e-learning/learners/main/get-course-recommend'
export const eLearningCourseGeneralComplete =
  '/e-learning/course/general/complete'
export const eLearningCourseOicComplete = '/e-learning/course/oic/complete'
export const eLearningAllLearningPath =
  '/e-learning/all-learning-path/get-learning-path'
export const eLearningMainLearningPath =
  '/e-learning/learners/main/get-learning-path-recommend'
export const eLearningLearningPathDetail =
  '/e-learning/get-learning-path-detail'
export const eLearningLikeLearningPath = '/e-learning/learning-path/like'
export const eLearningEnrollLearningPath = '/e-learning/learning-path/register'

export const eLearningCourseBatchLearner =
  '/e-learning/get-course-batch-learner'
export const eLearningCourseBatchLearnerLog =
  '/e-learning/get-course-batch-learner-log'
// e-learning collection
export const eLearningCollectionRecommend =
  '/e-learning/learners/main/get-colection-recommend'
export const eLearningCollectionDetail = '/e-learning/get-collection-detail'
export const eLearningCollectionFilter = '/e-learning/all-collection'
export const eLearningLikeCollection = '/e-learning/collection/like'

export const eLearningCancelCourse = '/e-learning/cancel-course'
export const eLearningGetCertificate = '/e-learning/get-certificate'
export const eLearningLearningPathGrade =
  '/e-learning/get-learning-path-certificate'
export const eLearningLearningPathCertification =
  '/e-certification/learning-path-certification'

// e-examination
export const eExaminationGetGender = '/examination/gender'
export const eExaminationGetQualification = '/examination/qualification'
export const eExaminationGetAddress = '/examination/address/:zipcode'
export const eExaminationUploadIdCard = '/file/examination/idcard'
export const eExaminationGetFieldOption = '/examination/schedule/filter/option'
export const eExaminationPostScheduleFilter = '/examination/schedule/filter'
export const eExaminationUserFilter = '/examination/user/filter'
export const eExaminationPostStudent = '/examination/student'
export const eExaminationGetConsent = 'examination/consent'
export const eExaminationMyScheduleFilter = '/examination/my/schedule/filter'
export const eExaminationAnnounceFilter = '/examination/announce/filter'
export const eExaminationAnnouncementDetail = '/examination/announce'
export const eExaminationGetMyExaminerProfile = '/examination/user'
export const eExaminationCheckOtherDocConfig =
  '/examination/check-other-doc-config'
export const eExaminationUploadOtherDocs = '/file/examination/other-docs'
export const eExaminationCheckDuplicateData =
  '/examination/check-duplicate-data'

// learning-point
export const learninPointCatalogFilter = '/catalog'
export const learninPointMyPoint = '/catalog/my-point'
export const profilePointHistory = '/user/point-history'
export const profileBadge = '/user/profile-badge'
export const redeemableCatalog = '/user/redeemable-catalog'
export const credential = '/credential'

export const eConfiguration = '/e-configuration'

// E-Content
export const eContentFilter = '/e-content/filter'
export const eContentById = '/e-content'
export const eContentRelated = '/e-content/related-content'
export const getEContentOptions = '/e-content/filter/options'
export const getEContentSubCategoryOptions =
  '/e-content/filter/sub-category-options'
