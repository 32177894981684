import _ from 'lodash'
import { store } from 'src/App'
import { openErrorDialog } from 'src/redux/slices/alertDialog'
import { setButtonLoading } from 'src/redux/slices/eTesting'
import { checkDisplayOneAndNow } from 'src/redux/slices/eTesting/events'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import {
  postETestingSubmit,
  postETestingAnswerNow,
} from 'src/services/eTesting'

export const handleSubmit = (props) => async (dispatch) => {
  const { uuid, trainingPlan, eTesting } = props
  const isOneAndNow = checkDisplayOneAndNow(eTesting)
  const answerETesting = _.get(eTesting, 'answerETesting', [])
  const body = {
    trainingPlanUuid: trainingPlan || '',
    answerETesting: answerETesting
      .filter((item) => item.isAnswered)
      .map((item) => ({
        questionUuid: item.questionUuid,
        answerUuid: item.answerUuid,
        freetextAnswer: item.freetextAnswer,
        isValueAns: _.get(item, 'isValueAns', ''),
        sequenceList: _.get(item, 'sequenceList', []),
        matchingAns: _.get(item, 'matchingAns', []),
        fillAns: _.get(item, 'fillAns', []),
      })),
  }
  const payload = { uuid, body }

  if (eTesting.isModule) {
    const {
      course,
      selectedLesson,
      eLearningCourseBatchLearner,
      selectedLesson: { eLearningModule },
    } = store.getState().eLearningLearning
    if (selectedLesson.isSubmitted) {
      dispatch(
        openErrorDialog({
          title: 'ไม่สามารถส่งคำตอบได้',
          message: [
            'เนื่องจากคุณผ่านบทเรียนนี้ไปแล้ว ก่อนการเปลี่ยนแปลงหลักสูตร',
          ],
        })
      )
      return
    }

    payload.uuid = _.get(selectedLesson, 'eTestingUuid', '')
    payload.body['eLearningCourse'] = { id: course.id, uuid: course.uuid }
    payload.body['eLearningCourseBatchLearner'] = eLearningCourseBatchLearner
    payload.body['eLearningModule'] = eLearningModule
    payload.isModule = true
    delete payload.body.trainingPlanUuid
  }

  if (isOneAndNow) {
    const questions = _.get(eTesting, 'questions', [])
    const page = _.get(eTesting, 'page', 1)
    const question = _.get(questions, [page - 1], {})
    const questionUuid = _.get(question, 'uuid', '')
    const eTestingUuid = _.get(eTesting, 'uuid', '')
    dispatch(setButtonLoading())
    await dispatch(postETestingAnswerNow({ uuid: eTestingUuid, questionUuid }))
    setTimeout(() => {
      dispatch(handleSubmitETesting(payload))
    }, 3000)
  } else {
    dispatch(handleSubmitETesting(payload))
  }
}

export const handleSubmitETesting = (payload) => async (dispatch) => {
  dispatch(startLoading())
  await dispatch(postETestingSubmit(payload))
  dispatch(stopLoading())
}
