export const themeColor = {
  primary: {
    light: '#4040AB',
    main: '#00008F',
    dark: '#000072',
    contrast: '#FFFFFF',
    containedHoverBackground:
      ' linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #00008F',
    outlinedHoverBackground: 'rgba(73, 118, 186, 0.08)',
    outlinedResting: 'rgba(0, 0, 143, 0.5)',
    secondary: '#494DF4',
  },
  secondary: {
    light: '#F49889',
    main: '#F07662',
    dark: '#C05E4E',
    contrast: '#FFFFFF',
    containedHoverBackground:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #F07662',
    outlinedHoverBackground: 'rgba(240, 118, 98, 0.08)',
    outlinedResting: 'rgba(240, 118, 98, 0.5)',
  },
  success: {
    light: '#55D37A',
    main: '#1CC54E',
    dark: '#169E3E',
    contrast: '#FFFFFF',
    containedHoverBackground:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #4CAF50',
    outlinedHoverBackground: 'rgba(28, 197, 78, 0.08)',
    outlinedResting: 'rgba(28, 197, 78, 0.5)',
    content:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #1CC54E',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #1CC54E',
  },
  warning: {
    light: '#FFB547',
    main: '#ED6C02',
    dark: '#C77700',
    contrast: '#FFFFFF',
    containedHoverBackground:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #ED6C02',
    outlinedHoverBackground: 'rgba(237, 108, 2, 0.08)',
    outlinedResting: 'rgba(237, 108, 2, 0.5)',
    content:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #ED6C02',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ED6C02',
  },
  error: {
    light: '#D64F65',
    main: '#C91432',
    dark: '#A11028',
    contrast: '#FFFFFF',
    containedHoverBackground:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #F44336',
    outlinedHoverBackground: 'rgba(201, 20, 50, 0.08)',
    outlinedResting: 'rgba(201, 20, 50, 0.5)',
    content:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #C91432',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C91432',
  },
  info: {
    light: '#777AF7;',
    main: '#494DF4',
    dark: '#3A3EC3',
    displayCircle:
      'linear-gradient(180deg, #494DF4 0%, rgba(73, 77, 244, 0) 100%)',
    contrast: '#FFFFFF',
    containedHoverBackground:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #494DF4',
    outlinedHoverBackground: 'rgba(73, 77, 244, 0.08)',
    outlinedResting: 'rgba(73, 77, 244, 0.5)',
    content:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #494DF4',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4976BA',
  },
  white: { main: '#FFFFFF', containedHoverBackground: '#FFFFFF' },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    selected: 'rgba(0, 0, 0, 0.08)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: '#F5F5F5',
    focus: 'insetrgba(0, 0, 0, 0.12)',
  },
  other: {
    divider: 'rgba(0, 0, 0, 0.07)',
    dividerWhite: 'rgba(255, 255, 255, 0.15)',
    outlineBorder: 'rgba(0, 0, 0, 0.23)',
    standardInputLine: 'rgba(0, 0, 0, 0.42)',
    backdropOverlay: 'rgba(0, 0, 0, 0.5)',
    ratingActive: '#FFB400',
    snackbarBackground: '#323232',
    filledInputBackground: '#FAFAFA',
    learnerHeader: 'linear-gradient(180deg, #00008F 0%, #000072 100%)',
  },
  text: {
    primary: '#333333',
    secondary: '#5F5F5F',
    gray: '#7F7F7F',
    lightGray: '#999999',
    silver: '#CCCCCC',
    mercury: '#E5E5E5',
    wildSand: '#F5F5F5',
    alabaster: '#FAFAFA',
    white: '#FFFFFF',
    disabled: 'rgba(0, 0, 0, 0.38)',
    lightBlue: 'rgba(219, 228, 241, 0.6)',
  },
  silver: {
    primary: '#CCCCCC',
  },
  blue: {
    deepDapphire: '#00005B',
    stTropaz: '#2425AA',
    azure: '#3032C1',
    darkIndigo: '#3B3FD8',
    indigo: '#494DF4',
    oceanBlue: '#4976BA',
    blueLine: 'rgba(219, 228, 241, 0.6)',
    blueBorder: '#DBE4F1',
  },
  boxShadow: {
    menu: '0px 4px 4px rgba(204, 204, 204, 0.1)',
    menuInset: 'inset 0px 4px 4px rgba(204, 204, 204, 0.1)',
    displayCircle: '0px 4px 12px 0px rgba(0, 0, 91, 0.2)',
    oceanBlue: '0px 12px 40px 0px #4976BA1A',
  },
  background: {
    paper: '#FFFFFF',
    default: '#FAFAFA',
    blue: '#F8FAFC',
    basic: '#F2F4FA',
    blur: '#FAFAFA80',
  },
  common: {
    white: '#FFFFFF',
    black: '#000000',
  },
  grey: {
    grey50: '#FAFAFA',
    grey100: '#F5F5F5',
    grey200: '#EEEEEE',
    grey300: '#E0E0E0',
    grey400: '#BDBDBD',
    grey500: '#9E9E9E',
    grey600: '#757575',
    grey700: '#616161',
    grey800: '#424242',
    grey900: '#212121',
    greyA100: '#D5D5D5',
    greyA200: '#AAAAAA',
    greyA400: '#616161',
    greyA700: '#303030',
  },
  axa: {
    teal: '#027180',
  },
  rating: {
    theBest: '#B2DFDB',
    great: '#C8E6C9',
    veryGood: '#DCEDC8',
    good: '#F0F4C3',
    ok: '#FFF9C4',
    notBad: '#FFECB3',
    notOk: '#FFE0B2',
    bad: '#FFCCBC',
    veryBad: '#FFCDD2',
    worst: '#F8BBD0',
  },
}

export const btnSize = { s: 30, m: 40, l: 50 }

export const fontStyle = {
  h1: {
    fontSize: 100,
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '112px',
    letterSpacing: '-1.5px',
  },
  h2: {
    fontSize: 64,
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '72px',
    letterSpacing: '-0.5px',
  },
  h3: {
    fontSize: 52,
    fontWeight: 900,
    fontStyle: 'normal',
    lineHeight: '56px',
    letterSpacing: '0px',
  },
  h4: {
    fontSize: 38,
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '42px',
    letterSpacing: '0.25px',
  },
  h4Light: {
    fontSize: 38,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '42px',
    letterSpacing: '0.25px',
  },
  h5: {
    fontSize: 28,
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '32px',
    letterSpacing: '0px',
  },
  h6: {
    fontSize: 24,
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '32px',
    letterSpacing: '0.15px',
  },
  subtitle1: {
    fontSize: 20,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  subtitle2: {
    fontSize: 18,
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  body1: {
    fontSize: 20,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  body1b: {
    fontSize: 20,
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  body2: {
    fontSize: 18,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: '0.15px',
  },
  body2b: {
    fontSize: 18,
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: '0.15px',
  },
  body3: {
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '16px',
    letterSpacing: '0px',
  },
  body3b: {
    fontSize: 14,
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '16px',
    letterSpacing: '0px',
  },
  body4: {
    fontSize: 10,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '12px',
    letterSpacing: '0px',
  },
  body4b: {
    fontSize: 10,
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '12px',
    letterSpacing: '0px',
  },
  button: {
    fontSize: 18,
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: '0.4px',
  },
  buttons: {
    fontSize: 17,
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '22px',
    letterSpacing: '0.46px',
  },
  buttonl: {
    fontSize: 19,
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '26px',
    letterSpacing: '0.46px',
  },
  caption: {
    fontSize: 16,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '18px',
    letterSpacing: '0.4px',
  },
  overline: {
    fontSize: 16,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '32px',
    letterSpacing: '1px',
  },
  avatarletter: {
    fontSize: 24,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: '0.14px',
  },
  inputlabel: {
    fontSize: 16,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '12px',
    letterSpacing: '0.15px',
  },
  helpertext: {
    fontSize: 16,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: '0.4px',
  },
  inputtext: {
    fontSize: 20,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  tooltip: {
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '14px',
    letterSpacing: '0px',
  },
  tooltipb: {
    fontSize: 14,
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '14px',
    letterSpacing: '0px',
  },
  chip: {
    fontSize: 17,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '18px',
    letterSpacing: '0.16px',
  },
  alertTitle: {
    fontSize: 20,
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  coverText: {
    fontSize: 52,
    fontWeight: 500,
    lineHeight: '40px',
    fontStyle: 'normal',
    WebkitTextStroke: '2px #fff',
    textShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    padding: '32px 12px',
    top: '50%',
    transform: 'translateY(-50%)',
    background:
      'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%)',
  },
  tableHeader: {
    fontSize: 18,
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: '0.14px',
  },
}
