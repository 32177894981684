import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  getETesting,
  postETestingSubmit,
  getETestingScore,
  getETestingAnswer,
  postETestingAnswerNow,
} from 'src/services/eTesting'
import { handleAnswerType, handleInitialData } from './events'
import { initialState } from './model'

const eTesting = createSlice({
  name: 'eTesting',
  initialState: initialState,
  reducers: {
    setInitialPreview: (state, { payload }) => {
      Object.assign(state, payload)
    },
    resetAnswer: (state) => {
      state.answerETesting = []
      state.onReviewAnswer = false
      state.onShowAnswer = false
    },
    setAnswered: (state, { payload }) => {
      const { uuid } = payload
      const answerState = state.answerETesting.find(
        (item) => item.questionUuid == uuid
      )
      handleAnswerType(state, answerState, payload)
    },
    setButtonLoading: (state) => {
      state.isButtonLoading = true
    },
    setVideoEnded: (state, { payload }) => {
      const answerState = state.answerETesting.find(
        (item) => item.questionUuid == payload
      )
      _.set(answerState, 'isVideoEnded', true)
    },
    // Display question one per page
    setNextQuestion: (state) => {
      state.onShowAnswer = false
      state.isButtonLoading = false
      state.page = state.page + 1
    },
    setPreviousQuestion: (state) => {
      state.page = state.page - 1
    },
    setSectionTesting: (state) => {
      state.section = 'TESTING'
      state.page = 1
    },
    setSectionResult: (state) => {
      state.section = 'RESULT'
    },
    setSectionTimeUp: (state) => {
      state.section = 'TIMEUP'
    },
    setMobileProps: (state, { payload }) => {
      state.mobileProps.isMobile =
        payload.isMobile || state.mobileProps.isMobile
      state.mobileProps.selectedItem = payload.selectedItem || null
    },
    resetOnViewState: (state) => {
      state.onReviewAnswer = false
      state.onShowAnswer = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getETesting.fulfilled, (state, { payload }) => {
        handleInitialData(state, payload)
        const isETestingOpen = _.get(payload.data, 'isETestingOpen', false)
        if (!isETestingOpen) state.section = 'RESULT'
        state.isButtonLoading = false
      })
      .addCase(getETesting.rejected, (state, { meta }) => {
        state.error = meta.response.data.constraints || meta?.response?.data
        state.section = 'RESULT'
      })
      .addCase(postETestingAnswerNow.fulfilled, (state, { meta, payload }) => {
        const questionUuid = _.get(meta.arg, 'questionUuid', '')
        const answers = _.get(payload, 'data', [])
        state.correctAnswers.push({ questionUuid, answers })
        state.isButtonLoading = true
        state.onShowAnswer = true
      })
      .addCase(postETestingAnswerNow.rejected, (state, { meta }) => {
        console.log(meta.response.data?.constraints)
      })
      .addCase(postETestingSubmit.fulfilled, (state) => {
        state.section = 'RESULT'
        state.isTimeUp = false
      })
      .addCase(postETestingSubmit.rejected, (state, { meta }) => {
        state.error = meta.response.data.constraints || meta?.response?.data
        state.section = 'RESULT'
      })
      .addCase(getETestingScore.fulfilled, (state, { payload }) => {
        state.result = payload.data
      })
      .addCase(getETestingScore.rejected, (state, { meta }) => {
        console.log(meta.response.data?.constraints)
        state.section = 'RESULT'
      })
      .addCase(getETestingAnswer.fulfilled, (state, { payload }) => {
        handleInitialData(state, payload)
        state.onReviewAnswer = true
      })
      .addCase(getETestingAnswer.rejected, (state, { meta }) => {
        state.error = meta.response.data.constraints || meta?.response?.data
        state.section = 'RESULT'
      })
  },
})

export const {
  setInitialPreview,
  resetAnswer,
  setAnswered,
  setButtonLoading,
  setNextQuestion,
  setPreviousQuestion,
  setVideoEnded,
  setSectionTesting,
  setSectionResult,
  setSectionTimeUp,
  setMobileProps,
  resetOnViewState,
} = eTesting.actions

export default eTesting.reducer
